import mock from '../mock';

const birthdayDB = {
	gift: {
		resMessage: 'Fetch record successfully.',
		resCode: '00',
		errCode: null,
		data: {
			prize: '1'
		}
	},
	response: {
		resMessage: 'Created record successfully.',
		resCode: '00',
		errCode: null,
		data: {
			phone: '',
			prize: ''
		}
	}
};

mock.onPost('/api/birthday/getByScatch').reply(config => {
	const data = JSON.parse(config?.data);
	console.log('💘', data);
	return [200, birthdayDB.gift];
});

mock.onPost('/api/birthday/phone').reply(request => {
	const data = JSON.parse(request?.data);
	return [200, { ...birthdayDB.response, data: { ...data } }];
});
