import history from '@history';
import OtpService from 'app/services/otpService';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import { setUser } from './userSlice';

const { createSlice } = require('@reduxjs/toolkit');

export const requestOtp = payload => dispatch => {
	dispatch(setLoadingBtn(true));
	return OtpService.sendSMS(payload)
		.then(respond => {
			if (respond.resCode === '00') {
				dispatch(
					showMessage({
						message: respond.resMessage,
						autoHideDuration: 6000,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
				dispatch(setLoadingBtn(false));
				return dispatch(requestOtpData(respond.data));
			}
			dispatch(setLoadingBtn(false));
			return dispatch(
				showMessage({
					message: respond.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: 'error'
				})
			);
		})
		.catch(errors => {
			dispatch(
				showMessage({
					message: errors.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: errors.resCode === '00' ? 'success' : 'error'
				})
			);
			dispatch(setLoadingBtn(false));
			return dispatch(errorData(errors));
		});
};

export const verifyOtp = payload => dispatch => {
	return OtpService.verifySMS(payload)
		.then(respond => {
			if (respond.resCode === '00') {
				dispatch(
					showMessage({
						message: respond.resMessage,
						autoHideDuration: 6000,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
				
				dispatch(verifyOtpData(respond.data));
				
				dispatch(setUser(respond.data.userData));

				// console.log(respond.data);
				// // ----====== For testing only ========--------	
				// return history.push(
				// 	`/policy-summary/protect-me/${respond.data.refId}/${respond.data.language}`
				// );
				// ////---====== Must delete before deploy ======-----------


				//  Term Life (Pure Protect)
				if (respond.data.product.includes('TM01')) {
					return history.push(
						`/policy-summary/life-cancer-care/${respond.data.refId}/${respond.data.language}`
					);
				}
				// 2023-05-12 KOURNGMENG: Enhance to display Protect Me Product
				// Jira: https://fwdthailand.atlassian.net/browse/CBD-3230
				if (respond.data.product.includes('TM02') || respond.data.product.includes('TM03') || respond.data.product.includes('TM04')) {
					return history.push(
						`/policy-summary/protect-me/${respond.data.refId}/${respond.data.language}`
					);
				}

				// 2024-04-30 KOURNGMENG: Enhance to display New Endowment Product (FWD Sar Tor Chivit)
				// Jira: https://fwdthailand.atlassian.net/browse/CBD-3823
				if (respond.data.product.includes('EN04') || respond.data.product.includes('EN05') || respond.data.product.includes('EN06')) {
					return history.push(
						`/policy-summary/sar-tor-chivit/${respond.data.refId}/${respond.data.language}`
					);
				}

				// CL1: Credit life : In Credit it has CL01, AR01, CL02, AR02
				if (respond.data.product === 'CL01') {
					return history.push(
						`/policy-summary/loan-protection/${respond.data.refId}/${respond.data.language}`
					);
				}

				// CL1: Credit life : In Credit it has CL01, AR01, CL02, AR02
				if (respond.data.product === 'CL02') {
					return history.push(
						`/policy-summary/loan-protection-mlta/${respond.data.refId}/${respond.data.language}`
					);
				}

				// Saving Product
				// EN01 
				return history.push(`/policy-summary/short-saving/${respond.data.refId}/${respond.data.language}`);


			}
			return dispatch(
				showMessage({
					message: respond.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: 'error'
				})
			);
		})
		.catch(errors => {
			dispatch(
				showMessage({
					message: errors.message,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: errors.resCode === '00' ? 'success' : 'error'
				})
			);
			return dispatch(errorData(errors));
		});
};

const initialState = {
	otpKey: '',
	refCode: '',
	token: '',
	loadingBtn: false
};

const otpSlice = createSlice({
	name: 'otp',
	initialState,
	reducers: {
		requestOtpData: (state, action) => {
			state.otpKey = action.payload.otpKey;
			state.refCode = action.payload.refCode;
		},
		verifyOtpData: (state, action) => {
			state.token = action.payload.token;
			localStorage.setItem('jwt_access_token', action.payload.token);
			axios.defaults.headers.common.Authorization = `Bearer ${action.payload.token}`;
		},
		setLoadingBtn: (state, action) => {
			console.log(action.payload);
			state.loadingBtn = action.payload;
		},
		errorData: (state, action) => { }
	}
});

export const { requestOtpData, verifyOtpData, errorData, setLoadingBtn } = otpSlice.actions;

export default otpSlice.reducer;
