import React from 'react';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';
import kh from './i18n/kh';
import kh1 from './i18n/kh1';
import en1 from './i18n/en1';
import en2 from './i18n/en2';
import kh2 from './i18n/kh2';
import en3 from './i18n/en3-mlta';
import kh3 from './i18n/kh3-mlta';

i18next.addResourceBundle('en', 'policy-summary', en);
i18next.addResourceBundle('kh', 'policy-summary', kh);
i18next.addResourceBundle('kh', 'policy-summary-cancer', kh1);
i18next.addResourceBundle('en', 'policy-summary-cancer', en1);
i18next.addResourceBundle('kh', 'policy-summary-loan', kh2);
i18next.addResourceBundle('en', 'policy-summary-loan', en2);
i18next.addResourceBundle('kh', 'policy-summary-loan-mlta', kh3);
i18next.addResourceBundle('en', 'policy-summary-loan-mlta', en3);

const PolicySummaryConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.admin,
  routes: [
    {
      path: '/policy-summary/short-saving/:refId/:language/',
      component: React.lazy(() => import('./PolicySummary'))
    },
    {
      path: '/policy-summary/life-cancer-care/:refId/:language/',
      component: React.lazy(() => import('./PolicySummaryLifeCancerCare'))
    },

    // 2023-05-12 KOURNGMENG: Enhance to display Protect Me Product
    // https://fwdthailand.atlassian.net/browse/CBD-3230
    {
      path: '/policy-summary/protect-me/:refId/:language/',
      component: React.lazy(() => import('./PolicySummaryProtectMe'))
    },

   
      // 2024-04-30 KOURNGMENG: Enhance to display New Endowment Product (FWD Sar Tor Chivit)
      // Jira: https://fwdthailand.atlassian.net/browse/CBD-3823
    {
      path: '/policy-summary/sar-tor-chivit/:refId/:language/',
      component: React.lazy(() => import('./PolicySummarySarTorChivit'))
    },
    {
      path: '/policy-summary/loan-protection/:refId/:language/',
      component: React.lazy(() => import('./PolicySummaryLoanProtection'))
    },
    {
      path: '/policy-summary/loan-protection-mlta/:refId/:language/',
      component: React.lazy(() => import('./PolicySummaryLoanProtectionMLTA'))
    }
  ]
};

export default PolicySummaryConfig;
