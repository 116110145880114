import CryptoJS from 'crypto-js';

const { default: axios } = require('axios');

class OtpService {
	sendSMS = payload => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_BASE_URL}/otp/request`, {
					refId: payload.refId
				})
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				});
		});
	};

	verifySMS = payload => {
		// const pwsEncrypted = CryptoJS.AES.encrypt(payload.otpCode, process.env.REACT_APP_SALT_LOGIN).toString();
		// const data = { email, password: pwsEncrypted, token };
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_BASE_URL}/otp/login`, {
					refId: payload.refId,
					otpCode: payload.otpCode,
					otpKey: payload.otpKey,
					refCode: payload.refCode
				})
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				});
		});
	};
}

const instance = new OtpService();

export default instance;
