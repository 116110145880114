import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';
import kh from './i18n/kh';

i18next.addResourceBundle('en', 'otp', en);
i18next.addResourceBundle('tr', 'otp', tr);
i18next.addResourceBundle('ar', 'otp', ar);
i18next.addResourceBundle('kh', 'otp', kh);

const OtpConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/otp/:refId/:phoneNumber/:language/:product',
			component: React.lazy(() => import('./Otp'))
		},
		{
			path: '/otp/:refId/:phoneNumber/:language',
			component: React.lazy(() => import('./Otp'))
		}
	]
};

export default OtpConfig;
