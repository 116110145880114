import CustomerService from 'app/services/customerService';
import { showMessage } from 'app/store/fuse/messageSlice';
import history from '@history';

const { createSlice } = require('@reduxjs/toolkit');

export const requestSMS = payload => dispatch => {
	return CustomerService.sendPolicySummarySMS(payload)
		.then(respond => {
			dispatch(
				showMessage({
					message: respond.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: respond.resCode === '00' ? 'success' : 'error'
				})
			);
			dispatch(requestSMSData(respond.data));
			return history.push(`/otp/${respond.data.refId}/${respond.data.phoneNumber}/${respond.data.language}`);
		})
		.catch(errors => {
			dispatch(
				showMessage({
					message: errors.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: errors.resCode === '00' ? 'success' : 'error'
				})
			);
			return dispatch(errorData(errors));
		});
};

const initialState = {};

const customerSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		requestSMSData: (state, action) => {},
		errorData: (state, action) => {}
	}
});

export const { requestSMSData, errorData } = customerSlice.actions;

export default customerSlice.reducer;
