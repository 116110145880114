const locale = {
  HEADER1: 'សូមទាញយក និងចុះឈ្មោះចូលប្រើប្រាស់កម្មវិធី Omne by FWD ដើម្បីមើលសំណុំឯកសារបណ្ណសន្យារ៉ាប់រងរបស់លោកអ្នក។',
  HEADER2: 'សូមថ្លែងអំណរគុណដែលបានជ្រើសរើសយកការធានារ៉ាប់រងពីក្រុមហ៊ុនយើងខ្ញុំ។',
  HEADER3: 'សំណុំឯកសារនៃបណ្ណសន្យារ៉ាប់រងរបស់លោកអ្នក មានរក្សាទុកនៅក្នុងកម្មវិធីទូរស័ព្ទដៃ FWD Moments។',
  HOW_ACCESS: 'វិធីចូលមើលសំណុំឯកសារនៃបណ្ណសន្យារ៉ាប់រងរបស់លោកអ្នក',
  STEP1: 'ជំហានទី 1',
  STEP1_CONTENT: 'ទាញយកកម្មវិធី FWD Moments ពី AppStore ឬ Google Play Store',
  STEP2: 'ជំហានទី 2',
  STEP2_CONTENT: 'ចុះឈ្មោះចូលប្រើប្រាស់ក្នុងកម្មវិធី FWD Moments',
  STEP3: 'ជំហានទី 3',
  STEP3_CONTENT: 'ចូលមើលសំណុំឯកសារនៃបណ្ណសន្យារ៉ាប់រងរបស់លោកអ្នកក្នុង “e-Statement”',
  DOWNLOAD_MOMENT: 'ទាញយកកម្មវិធី FWD Moments',
  DOWNLOAD: 'ចុចទីនេះដើម្បីទាញយក',
  REGISTER_MOMENT: 'របៀបចុះឈ្មោះ Moments'
};

export default locale;
