const { default: axios } = require('axios');

class CustomerService {
	sendPolicySummarySMS = payload => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${process.env.REACT_APP_BASE_URL}/customer`, {
					phoneNumber: payload.phone,
					policyNumber: payload.policyNumber,
					language: payload.language
				})
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				});
		});
	};
}

const instance = new CustomerService();

export default instance;
