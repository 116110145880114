const locale = {
  HEADER: 'អបអរសាទរ!',
  P1: 'លោកអ្នកទទួលបានការធានារ៉ាប់រងហើយ។',
  P2: 'ខាងក្រោមនេះគឺជាសេចក្ដីសង្ខេបនៃបណ្ណសន្យារ៉ាប់រងរបស់លោកអ្នក។ សូមមេត្តាត្រួតពិនិត្យ និងបញ្ជាក់ពីការទទួលយក។',
  PRODUCT_NAME: 'FWD សងកម្ចីពេញលេញ',
  SA: 'ទំហំទឹកប្រាក់ធានារ៉ាប់រង',
  PREMIUM: 'បុព្វលាភធានារ៉ាប់រង',
  PREMIUM_AMT: 'amount',
  POLICY_NO: 'លេខបណ្ណសន្យារ៉ាប់រង',
  LIFE_INSURED: 'អ្នកត្រូវបានធានារ៉ាប់រង',
  POLICY_OWNER: 'ម្ចាស់បណ្ណសន្យារ៉ាប់រង',
  IDENTIFICATION_NO: 'លេខអត្តសញ្ញាណប័ណ្ណ',
  DOB: 'ថ្ងៃខែឆ្នាំកំណើត',
  GENDER: 'ភេទ',
  EFFECTIVE_DATE: 'កាលបរិច្ឆេទនៃសុពលភាពបណ្ណសន្យារ៉ាប់រង',
  MATURITY_DATE: 'កាលបរិច្ឆេទផុតកំណត់បណ្ណសន្យារ៉ាប់រង',
  PLAN_DETAILS: 'គម្រោងធានារ៉ាប់រង',
  PLAN_NAME: 'ឈ្មោះគម្រោង',
  PLAN: 'ជម្រើស',
  COVERAGE_PERIOD: 'រយ:ពេលដែលត្រូវធានា',
  PAYMENT_PERIOD: 'រយ:ពេលបង់បុព្វលាភ',
  COVERAGE_PERIOD_VALUE: 'value ឆ្នាំ',
  INSURED_BENEFITS: 'អត្ថប្រយោជន៍ធានារ៉ាប់រងសម្រាប់អ្នកត្រូវបានធានារ៉ាប់រង',
  TPD: 'អត្ថប្រយោជន៍សម្រាប់មរណភាព ឬពិការភាពទាំងស្រុង និងជាអចិន្ត្រៃយ៍',
  SA_TPD: 'ទំហំទឹកប្រាក់ធានារ៉ាប់រង',
  ADB: 'អត្ថប្រយោជន៍សម្រាប់មរណភាពបណ្តាលមកពីគ្រោះថ្នាក់',
  SA_ADB: 'ទំហំទឹកប្រាក់ធានារ៉ាប់រង',
  CI: 'អត្ថប្រយោជន៍សម្រាប់ជំងឺធ្ងន់ធ្ងរ*',
  SA_CI: 'ទំហំទឹកប្រាក់ធានារ៉ាប់រង',
  POB: 'អត្ថប្រយោជន៍ធានារ៉ាប់រងសម្រាប់ម្ចាស់បណ្ណសន្យារ៉ាប់រង',
  TBD: 'អត្ថប្រយោជន៍លើកលែងការបង់បុព្វលាភ ក្នុងករណីមរណភាព ពិការភាពទាំងស្រុង និងជាអចិន្ត្រៃយ៍ ឬជំងឺធ្ងន់ធ្ងរដំណាក់កាលចុងក្រោយ (CI)**',
  SA_TBD: 'ទំហំទឹកប្រាក់ធានារ៉ាប់រង',
  TBD_LUMPSUM:
    'អត្ថប្រយោជន៍ទទួលបានទឹកប្រាក់ធានារ៉ាប់រងសរុប ក្នុងករណីមរណភាព ពិការភាពទាំងស្រុង និងជាអចិន្ត្រៃយ៍ ឬជំងឺធ្ងន់ធ្ងរដំណាក់កាលចុងក្រោយ (CI)**',
  SA_TBD_LUMPSUM: 'ទំហំទឹកប្រាក់ធានារ៉ាប់រង',
  SA_TBD_REDUCING: '(ថយតាមឆ្នាំ)',
  MB: 'អត្ថប្រយោជន៍ដល់កាលកំណត់នៃបណ្ណធានារ៉ាប់រង',
  NOTED1: '* គ្រប់អាយុ៖ មហារីកដំណាក់កាលដំបូង មហារីកដំណាក់កាលចុងក្រោយ ជំងឺដាច់សរសៃឈាមខួរក្បាល ដោយសារការចុះអន់ថយនៃសរសៃប្រសាទជាអចិន្ត្រៃយ៍ ជំងឺគាំងបេះដូងនៃកម្រិតធ្ងន់ធ្ងរជាក់លាក់។',
  NOTED2: '* អាយុ 1 ខែ ដល់ 17 ឆ្នាំ៖ ជំងឺគ្រុនរលាក ជាមួយការខូតខាតមុខងារប្រឹសបេះដូង ជំងឺកាវ៉ាសាគី ជាមួយការវិវត្តនៃផលវិបាកបេះដូង ជំងឺទឹកនោមផ្អែមប្រភេទ១ ជំងឺគ្រុនឈាមធ្ងន់ធ្ងរ និងជំងឺទឹកដក់ក្នុងខួរក្បាលក្រោយចាប់កំណើតចំាបាច់មានបំពង់បង្ហូរ។',
  NOTED3: '** មហារីកដំណាក់កាលចុងក្រោយ ជំងឺដាច់សរសៃឈាមខួរក្បាលដោយសារការចុះអន់ថយនៃសរសៃប្រសាទជា អចិន្ត្រៃយ៍ ជំងឺគាំងបេះដូងនៃកម្រិតធ្ងន់ធ្ងរជាក់លាក់។',
  NOTED4: 'សូមជម្រាបជូនថា សំណុំឯកសារនៃបណ្ណសន្យារ៉ាប់រងរបស់លោកអ្នក មានរក្សាទុកនៅក្នុងកម្មវិធីទូរស័ព្ទ Omne by FWD ដែលលោកអ្នកអាចទាញយកទៅប្រើប្រាស់ពី AppStore ឬ Google Play Store។ ប្រសិនបើលោកអ្នកមានបញ្ហាទាញយក ឬចុះឈ្មោះចូលប្រើប្រាស់កម្មវិធី សូមទាក់ទងសេវាបម្រើអតិថិជន 087 33 99 82/078 33 99 88 ។',
  SINCERELY: 'ផ្តល់ជូនដោយ',
  ECO_NAME: 'ចាន់ រដ្ឋា',
  ECO_POS: 'អគ្គនាយក',
  COM: 'ក្រុមហ៊ុនអេហ្វដាប់ប៊ែលយូឌី ឡាយហ្វ៍ អ៊ិនសួរេន (ខេមបូឌា) ម.ក',
  ACK_APT: 'ខ្ញុំសូមបញ្ជាក់ពីការទទួលយកបណ្ណសន្យារ៉ាប់រងរបស់ខ្ញុំ',
  ACK_APT_1: 'ខ្ញុំបានពិនិត្យផ្ទៀងផ្ទាត់រួចហើយ នូវព័ត៌មានទាំងអស់របស់ខ្ញុំ ដែលបានបំពេញនៅក្នុងពាក្យស្នើសុំធានារ៉ាប់រង។',
  CONSENT: 'សូមចុចដើម្បីបញ្ចាក់ការទទួលយក។',
  SUBMIT: 'យល់ព្រម'
};

export default locale;
