const locale = {
	TITLE: 'Enter One-Time Password',
	CONTENT: 'Pls hit the button below to receive a one-time password via SMS in your mobile phone.',
	INPUT: 'One-time password',
	SUBMIT: 'Submit',
	RESEND: 'Send code',
	RESEND_D: 'Resend'
};

export default locale;
