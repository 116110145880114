const locale = {
  HEADER: 'Congratulations!',
  P1: 'You are now well protected.',
  P2: 'Here is your policy summary. Please double check and acknowledge.',
  PRODUCT_NAME: 'Loan Protection',
  SA: 'Sum assured',
  PREMIUM: 'Premium',
  PREMIUM_AMT: 'amount',
  POLICY_NO: 'Policy no.',
  LIFE_INSURED: 'Life insured',
  POLICY_OWNER: 'Policy owner',
  IDENTIFICATION_NO: 'Identification no.',
  DOB: 'Date of birth',
  GENDER: 'Gender',
  EFFECTIVE_DATE: 'Effective date',
  MATURITY_DATE: 'Maturity date',
  PLAN_DETAILS: 'Plan details',
  PLAN_NAME: 'Plan name',
  PLAN: 'Plan',
  COVERAGE_PERIOD: 'Coverage period',
  PAYMENT_PERIOD: 'Payment period',
  COVERAGE_PERIOD_VALUE: 'value Year(s)',
  INSURED_BENEFITS: 'Insured benefits',
  TPD: 'Death or Total Permanent Disability (TPD) Benefits',
  SA_TPD: 'Sum assured',
  ADB: 'Accidental Death Benefits (ADB)',
  SA_ADB: 'Sum assured',
  CI: 'Critical Illness (CI) Benefits*',
  SA_CI: 'Sum assured',
  POB: 'Policy owner benefits',
  TBD: 'Waiver of Premium on Death/TPD/Late stage CI **',
  SA_TBD: 'Sum assured',
  TBD_LUMPSUM: 'Lumpsum Benefit Payout on Death/TPD/Late stage CI **',
  SA_TBD_LUMPSUM: 'Sum assured',
  SA_TBD_REDUCING: '(Reducing)',
  MB: 'Maturity benefits',
  NOTED1: '* For all ages: Early stage Cancer, Late-Stage Cancer, Stroke with Permanent Neurological Deficit, Heart Attack or Specified Severity',
  NOTED2: '* For age 1 month - 17 years: Rheumatic Fever with Valvular Impairment, Kawasaki disease with Heart Complications, insulin dependent Type 1 Diabetes, sever Dengue Haemorrhagic Fever, and acquired hydrocephalus requiring an external shunt',
  NOTED3: '** Late-Stage Cancer, Stroke with Permanent Neurological Deficit, Heart Attack of Specified Severity',
  NOTED4: 'Please kindly be informed that the policy pack is available in Omne by FWD App, which you can download from AppStore or Google Play Store. If you have a problem downloading or signing up the app, please contact Customer Service at 087 33 99 82/078 33 99 88.',
  SINCERELY: 'Sincerely Yours',
  ECO_NAME: 'Chan Rotha',
  ECO_POS: 'Chief Executive Office',
  COM: 'FWD Life Insurance (Cambodia) Plc.',
  ACK_APT: 'I confirm that I agree to accept my policy.',
  ACK_APT_1: 'I have vet the information in the application form.',
  CONSENT: 'Please click to accept.',
  SUBMIT: 'Agree',
  TITLE_SURVEY: 'Customer Survey',
  TITLE_QUESTION_1: ''
};

export default locale;
