const { default: axios } = require('axios');

class PolicySummaryService {
	getPolicySummary = payload => {
		return new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.REACT_APP_BASE_URL}/policy-summary`,
					{
						refId: payload.refId
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('jwt_access_token')}`
						}
					}
				)
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				});
		});
	};

	getLinkMomentData = () => {
		return new Promise((resolve, reject) => {
			axios.post(`${process.env.REACT_APP_BASE_URL}/policy-ack/link`).then(response => {
				if (response.data) {
					resolve(response.data);
				} else {
					reject(response.data);
				}
			});
		});
	};

	policySubmitAck = payload => {
		return new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.REACT_APP_BASE_URL}/policy-ack`,
					{
						refId: payload.refId
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('jwt_access_token')}`
						}
					}
				)
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				});
		});
	};
}

const instance = new PolicySummaryService();

export default instance;
