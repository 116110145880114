import mock from '../mock';

const luckyScanDB = {
	gift: {
		resMessage: 'Fetch record successfully.',
		resCode: '00',
		errCode: null,
		data: {
			prize: '1'
		}
	},
	response: {
		resMessage: 'Created record successfully.',
		resCode: '00',
		errCode: null,
		data: {
			phone: '069252508',
			prize: '1'
		}
	}
};

mock.onGet('/api/getByScatch').reply(config => {
	return [200, luckyScanDB.gift];
});

mock.onPost('/api/phone').reply(request => {
	const data = JSON.parse(request?.data);
	console.log('🤏', request?.data);
	return [200, luckyScanDB.response];
});
