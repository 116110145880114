import { authRoles } from 'app/auth';
import { lazy } from 'react';

const BirthdayGiftConfig = {
	settings: {
		layout: {
			config: {
				toolbar: {
					gift: true
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/happy-birthday/sharing/:name',
			component: lazy(() => import('./BirthdayGiftSharing'))
		},
		{
			path: '/happy-birthday/thankyou',
			component: lazy(() => import('./ThankYou'))
		},
		{
			path: '/happy-birthday/rating',
			component: lazy(() => import('./BirthdayGiftRating'))
		},
		{
			path: '/happy-birthday',
			component: lazy(() => import('./BirthdayGift'))
		}
	]
};

export default BirthdayGiftConfig;
