const locale = {
	TITLE: 'បញ្ចូលលេខកូដសម្ងាត់មួយកាល',
	CONTENT: 'សូមចុចប៊ូតុងខាងក្រោមដើម្បីទទួលបាន លេខកូដសម្ងាត់ ក្នុងប្រអប់សារទូរស័ព្ទលេខ phone របស់លោកអ្នក។',
	INPUT: 'លេខកូដសម្ងាត់មួយកាល',
	SUBMIT: 'ចុចបញ្ជូន',
	RESEND: 'ផ្ញើលេខកូដសម្ងាត់',
	RESEND_D: 'ផ្ញើលេខកូដសម្ងាត់ម្តងទៀត'
};

export default locale;
