import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './registerSlice';
import user from './userSlice';
import customer from './customerSlice';
import otp from './otpSlice';
import policySummary from './PolicySummarySlice';

const authReducers = combineReducers({
	user,
	login,
	register,
	customer,
	otp,
	policySummary
});

export default authReducers;
