import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import kh from './i18n/kh';

i18next.addResourceBundle('en', 'GenericTemporaryCoveragePage', en);
i18next.addResourceBundle('kh', 'GenericTemporaryCoveragePage', kh);

const GenericTemporaryCoverageConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: '/generic-temporary-coverage/kh',
      component: React.lazy(() => import('./GenericTemporaryCoverageKh'))
    },
    {
      path: '/generic-temporary-coverage/en',
      component: React.lazy(() => import('./GenericTemporaryCoverageEn'))
    }
  ]
};

export default GenericTemporaryCoverageConfig;
