const locale = {
  HEADER1: 'You have acknowledged your policy pack.',
  HEADER2: 'Thank you for having your policy with us.',
  HEADER3: 'Your policy documents are available on the FWD Moments mobile app.',
  HOW_ACCESS: 'How to access your life insurance policy pack',
  STEP1: 'Step 1',
  STEP1_CONTENT: 'Download FWD Moments from AppStore or Google Play Store.',
  STEP2: 'Step 2',
  STEP2_CONTENT: 'Register with FWD Moments App',
  STEP3: 'Step 3',
  STEP3_CONTENT: 'Find your policy pack on “e-Statement',
  DOWNLOAD_MOMENT: 'Download FWD Moment App ',
  DOWNLOAD: 'Click here to download',
  REGISTER_MOMENT: 'How to Register Moment'
};

export default locale;
