import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import PolicySummaryConfig from 'app/main/policy-summary/PolicySummaryConfig';
import CongratulationConfig from 'app/main/congratulation/congratulationConfig';
import OtpConfig from 'app/main/example/OtpConfig';
import CustomerConfig from 'app/main/customer/CustomerConfig';
import Error404PageConfig from 'app/main/404/Error404PageConfig';
import GenericTemporaryCoverageConfig from 'app/main/generic-temporary-coverage/GenericTemporaryCoverageConfig';
import BirthdayGiftConfig from 'app/main/birthday-gift/BirthdayGiftConfig';
import LotteryScratchConfig from 'app/main/scratch-lottery/LotteryScratchConfig';
import LuckyScanConfig from 'app/main/lucky-scan/LuckyScanConfig';

const routeConfigs = [
	OtpConfig,
	PolicySummaryConfig,
	CongratulationConfig,
	CustomerConfig,
	Error404PageConfig,
	GenericTemporaryCoverageConfig,
	BirthdayGiftConfig,
	LotteryScratchConfig,
	LuckyScanConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Redirect to="/error-404" />
	},
	{
		path: '/policy-summary',
		component: () => <Redirect to="/policy-summary" />
	},
	{
		path: '/congratulation',
		component: () => <Redirect to="/congratulation" />
	},
	{
		path: '/customer',
		component: () => <Redirect to="/customer" />
	},
	{
		path: '/404',
		component: () => <Redirect to="/error-404" />
	}
];

export default routes;
