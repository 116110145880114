import { authRoles } from 'app/auth';
import { lazy } from 'react';

const LotteryScratchConfig = {
	settings: {
		layout: {
			config: {
				toolbar: {
					gift: true
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/lottery-scratch/:phone',
			component: lazy(() => import('./LotteryScratch'))
		},
		{
			path: '/lottery-scratch-special/1',
			component: lazy(() => import('./LotteryScratchSpecial'))
		},
		{
			path: '/lottery-scratch-special/2',
			component: lazy(() => import('./LotteryScratchSpecial2'))
		},
		{
			path: '/lottery-scratch-register',
			component: lazy(() => import('./LotteryScratchRegisterForm'))		// Landing page to scan QR Code: for Register
		},
		{
			path: '/lottery-scratch',
			component: lazy(() => import('./LotteryScratchForm'))
		}
	]
};

export default LotteryScratchConfig;
