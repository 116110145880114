import { authRoles } from 'app/auth';
import { lazy } from 'react';

const LuckyScanConfig = {
	settings: {
		layout: {
			config: {
				toolbar: {
					gift: true
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/lucky-scan/congrats/:phone/:prize',
			component: lazy(() => import('./Congrats'))
		},
		{
			path: '/lucky-scan/thankyou',
			component: lazy(() => import('./ThankYou'))
		},
		{
			path: '/lucky-scan',
			component: lazy(() => import('./LuckyScan'))
		}
	]
};

export default LuckyScanConfig;
