import React from 'react';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';
import kh from './i18n/kh';

i18next.addResourceBundle('en', 'congratulation', en);
i18next.addResourceBundle('kh', 'congratulation', kh);

const CongratulationConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.admin,
  routes: [
    // {
    //   path: '/congratulation/:language',
    //   component: React.lazy(() => import('./Congratulation'))
    // }
    // 2023-05-15 KOURNGMENG Add Policy pack after customer submit eACK
    {
      path: '/congratulation/:refId/:language',
      component: React.lazy(() => import('./Congratulation'))
    }
  ]
};

export default CongratulationConfig;


