import PolicySummaryService from 'app/services/policySummaryService';
import { showMessage } from 'app/store/fuse/messageSlice';
import history from '@history';

const { createSlice } = require('@reduxjs/toolkit');

export const getPolicySummary = payload => dispatch => {
	return PolicySummaryService.getPolicySummary(payload)
		.then(respond => {
			dispatch(
				showMessage({
					message: respond.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: respond.resCode === '00' ? 'success' : 'error'
				})
			);
			return dispatch(getPolicySummaryData(respond.data));
		})
		.catch(errors => {
			dispatch(
				showMessage({
					message: errors.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: errors.resCode === '00' ? 'success' : 'error'
				})
			);
			return dispatch(errorData(errors));
		});
};

export const getLinkMomentData = () => dispatch => {
	return PolicySummaryService.getLinkMomentData()
		.then(respond => {
			if (respond.resCode !== '00') {
				return dispatch(
					showMessage({
						message: respond.resMessage,
						autoHideDuration: 6000,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'right'
						},
						variant: 'error'
					})
				);
			}
			// dispatch(
			// 	showMessage({
			// 		message: respond.resMessage,
			// 		autoHideDuration: 6000,
			// 		anchorOrigin: {
			// 			vertical: 'bottom',
			// 			horizontal: 'right'
			// 		},
			// 		variant: 'success'
			// 	})
			// );
			return dispatch(getLinkMoment(respond.data));
		})
		.catch(errors => {
			dispatch(
				showMessage({
					message: errors.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: 'error'
				})	
			);
			return dispatch(errorData(errors));
		});
};

export const submitPolicyAck = payload => dispatch => {
	return PolicySummaryService.policySubmitAck(payload)
		.then(respond => {
			if (respond.resCode !== '00' && respond.resCode !== '13') {
				return dispatch(
					showMessage({
						message: respond.resMessage,
						autoHideDuration: 6000,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'right'
						},
						variant: 'error'
					})
				);
			}
			dispatch(
				showMessage({
					message: respond.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: respond.resCode === '13' ? 'error' : 'success'
				})
			);

			dispatch(submitPolicyAckData(respond.data));
			
			// 2023-05-15 KOURNGMENG: Add Policy Pack after customer submit eACK
			// return history.push(`/congratulation/${respond.data.language}`);
			return history.push(`/congratulation/${payload.refId}/${respond.data.language}`);

		})
		.catch(errors => {
			dispatch(
				showMessage({
					message: errors.resMessage,
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right'
					},
					variant: errors.resCode === '00' ? 'success' : 'error'
				})
			);
			return dispatch(errorData(errors));
		});
};

const initialState = {
	policySummary: null,
	linkMoment: [],
	e_app: null,
	signatureCEO: null,
};

const policySummarySlice = createSlice({
	name: 'policy-summary',
	initialState,
	reducers: {
		getPolicySummaryData: (state, action) => {
			state.policySummary = action.payload.policy_summary;
			state.e_app = action.payload.e_app;
			state.signatureCEO = action.payload.signatureCEO;
		},
		submitPolicyAckData: (state, action) => { },
		getLinkMoment: (state, action) => {
			state.linkMoment = action.payload;
		},
		errorData: (state, action) => { }
	}
});

export const { getPolicySummaryData, submitPolicyAckData, errorData, getLinkMoment } = policySummarySlice.actions;

export default policySummarySlice.reducer;
